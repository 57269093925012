@import "../theme/index";

.loader {
  height: 100%;

  &--nested {
    position: absolute;
    background-color: rgba(#fff, 0.2);
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    z-index: 1;

    .box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .loader__wrapper {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;

    max-width: 150px;
    max-height: 150px;
  }

  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cat {
    position: relative;
    width: 100%;
    max-width: 20em;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
      background-image: url('../../assets/image/loader/spinnyweel.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      animation: rotating 3s cubic-bezier(.65, .54, .12, .93) infinite;
    }

    &:active > * {
      animation-play-state: running;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(720deg);
    }
    to {
      transform: none;
    }
  }
}