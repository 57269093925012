@use "sass:color";
@import "../theme/index";

.bot-chat {

  .article {
    margin-top: 0;

    &__heading {
      padding: 0 40px;
      font-size: 35px;
    }
  }

  &__navigate {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    min-width: 65px;
    height: 65px;
    display: inline-block;
    padding-top: 10px;

    @include respond-to(largedesktop) {
      display: none;
    }

    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}