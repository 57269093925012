@use "sass:color";
@import "../../theme/index";

.toggle-button {
  position: relative;
  width: 135px;
  height: 135px;
  min-width: 135px;
  min-height: 135px;
  padding: 11px 0 9px 0;
  border: solid 1px $alto;
  cursor: pointer;
  user-select: none;
  margin: 4px;

  @include respond-to(mobile) {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  &__icon {
    width: 95px;
    height: 69px;
    margin: auto;
    margin-bottom: 7px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include respond-to(mobile) {
      width: 63px;
      height: 46px;
    }
  }

  &__title {
    font-size: 14px;
    color: $black;
    text-align: center;
    //white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  &--checked {
    background: $white;
  }

  &--checked::before {
    content: "";
    position: absolute;
    display: block;
    top: 127px;
    left: -1px;
    bottom: -1px;
    pointer-events: none;
    height: 7px;
    width: 135px;
    background-color: $lineGreen;

    @include respond-to(mobile) {
      top: 95px;
      width: 100px;
    }
  }

  &:hover:not(&--checked) {
    background-color: color.adjust($grey, $lightness: -2%);
  }

  &:active:not(&--checked) {
    background-color: color.adjust($grey, $lightness: -4%);
  }
}
