@import "../theme/index";

.default-signIn-btn {
  width: 260px;
  height: 100px;
  background: $blue;
  position: relative;
  font-size: 30px;
  font-weight: bold;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;

  @include respond-to(mobile) {
    height: 50px;
  }

  &:hover {
    cursor: pointer;
    color: $white;
  }

  &:focus {
    color: $white;
  }

  .signIn-btn-image {
    width: 54px;
    height: 60px;
    margin-right: 15px;

    @include respond-to(mobile) {
      width: 27px;
      height: 30px;
    }
  }
}

.default-auth-user {
  width: 180px;
  @include respond-to(tablets) {
    width: 200px;
  }
  @include respond-to(largedesktop) {
    width: 224px;
  }

  & {
    height: 100px;
    background: $blackApprox;
    color: $white;
    position: relative;
    font-size: 30px;
    font-weight: bold;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    @include respond-to(mobile) {
      height: 50px;
    }
  }

  .signIn-btn-image {
    width: 54px;
    height: 60px;
    margin-right: 15px;

    @include respond-to(mobile) {
      width: 27px;
      height: 30px;
    }
  }

  &__name {
    text-transform: uppercase;
  }

  &:hover {
    cursor: pointer;
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

.nav {

  &__desktop {
    display: none;
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__item {
    height: 50px;
    padding: 10px 0;
    border: none;
    background: transparent;
    border-bottom: 2px solid $lineGreen;
    @include respond-to(desktop) {
      border-bottom: 0;
    }

    &-text {
      font-size: 18px;
      line-height: 1.85;
      @include respond-to(largedesktop) {
        font-size: 22px;
        line-height: 1.5;
      }
      @include respond-to(widedesktop) {
        font-size: 24px;
        line-height: normal;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__img {
    width: 30px;
    height: auto;

    &-wrapper {
      height: auto;
      margin-right: 10px;
    }
  }
}

.menu-auth {
  width: 100%;
  height: 100vh;
  background: $grey;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: calc(100vh - 100px);

  &__name {
    text-transform: uppercase;
  }

  &--opened {
    position: relative;
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }

  &__items {
    list-style: none;
    width: 100%;
    min-height: 50px;
    padding: 0;
    margin: 0;
    transition: all .3s ease;
  }

  &__item {
    list-style: none;
    width: 100%;
    min-height: 50px;
    border-bottom: 2px solid $lineGreen;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: $black;
    font-size: 20px;
    text-align: left;
    transition: all .3s ease;
    border: none;
    background: transparent;
    padding: 10px 0;
    text-decoration: none;

    @include respond-to(tablets) {
      align-items: flex-start;
      padding: 10px 15px;
    }

    &:hover {
      color: $white;
      cursor: pointer;
    }
  }
  &__text {
    font-size: 14px;
    max-width: 210px;
    text-align: center;
    @include respond-to(tablets) {
      text-align: left;
    }
  }
}

.language {
  height: 50px;
  overflow: hidden;
  display: block;
  position: relative;

  &--open {
    height: auto;
  }

  &__title {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    padding: 25px;
    border: none;
    box-sizing: border-box;
    background: $grey;
    text-transform: uppercase;
    transition: all .3s ease;
    color: $darkGrey;

    &:first-child {
      background: transparent;
      margin-bottom: 2px;
    }

    &:hover {
      cursor: pointer;
      color: $blue;
      text-decoration: underline;
    }
  }
}

.header {
  width: 100%;
  height: 100px;
  background-color: $grey;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;

  @include respond-to(mobile) {
    height: 50px;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;

    @include respond-to(mobile) {
      height: 50px;
    }
  }

  &__logo {
    background-color: transparent;
    height: auto;
    width: 130px;
    border: none;
    cursor: pointer;
    padding: 0 5px;
    @include respond-to(tablets) {
      width: auto;
    }

    &-image {
      width: 100%;
      @include respond-to(tablets) {
        width: auto;
        height: 100%;
      }
    }
  }

  &-button {
    display: none;
  }

  &__language {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: fit-content;
    font-size: 20px;
    color: $black;
    text-transform: uppercase;
    background: $grey;
    overflow: hidden;

    &-wrapper {
      width: 200px;
      position: absolute;
      left: 105px;
      top: 30px;
      z-index: 1;
    }

    &-item {
      width: 30px;
      height: auto;
      margin-left: 150px;
      margin-top: 10px;
      z-index: 12;

      @include respond-to(mobile) {
        width: 27px;
        height: 30px;
      }

      &-icon {
        width: 100%;
        height: auto;
      }
    }
  }

  &__menu {
    transition: opacity 0.25s ease, height 0.3s ease;
    height: 100vh;
    opacity: 1;
    position: relative;
    background: $grey;

    &--hidden {
      transition: opacity 0.25s ease, height 0.3s ease;
      opacity: 0;
      visibility: hidden;
      height: 0;
    }
  }
}

.menu {
  margin: 0 auto;
  padding: 30px 0;
  height: calc(100vh - 100px);
  overflow: auto;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-button {
      width: 50%;
      @include respond-to(tablets) {
        width: 30%;
      }
    }

    &-link {
      color: $darkGrey;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin: 32px 0;
      display: block;
      text-decoration: none;

      &:hover {
        color: $white;
      }
    }
  }

  &__store {
    width: 50%;
    @include respond-to(tablets) {
      width: 30%;
    }

    &-img {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $blue;
    padding: 10px;
    width: 50%;
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    @include respond-to(tablets) {
      width: 30%;
    }

    &:hover {
      color: $white;
    }

    &-image {
      height: 40px;
      width: 40px;
      margin-right: 20px;
    }
  }
}

.burger {
  &__item {
    color: $black;
    border: none;
    background: transparent;
    padding: 0 20px;
    height: auto;
    width: 80px;
  }

  &__image {
    width: 100%;
    height: auto;
  }
}

@include respond-to(tablets) {
  .burger {
    &__item {
      padding: 0 50px;
      width: 150px;
    }
  }

  .menu-auth {
    width: 200px;
    background: $grey;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    opacity: 1;
    @include respond-to(largedesktop) {
      width: 224px;
    }
  }

  .nav {
    display: none;

    &__desktop {
      display: none;
    }

    &__mobile {
      display: flex;
    }

    &__item {
      &-wrapper {
        justify-content: flex-start;
        padding: 0 7px;
        @include respond-to(largedesktop) {
          padding: 0 20px;
        }
      }
    }
  }

  .language {

    &__title {
      font-size: 26.5px;
    }
  }

  .header {
    &__language {
      &-item {
        width: 59px;
        height: 57px;
        margin-top: 16px;
        margin-left: 130px;
        @include respond-to(largedesktop) {
          margin-left: 150px;
        }
      }

      &-wrapper {
        left: 280px;
        top: 45px;
      }
    }

    &__logo {
      height: 120px;
      margin: 0 25px;
      @include respond-to(largedesktop) {
        margin: 0 50px;
      }
    }
  }
}

@include respond-to(largedesktop) {
  .burger__item {
    display: none;
  }

  .default-authUser {
    display: flex;
  }

  .header {
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu {
    display: none;
  }

  .nav {
    display: none;

    &__desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__mobile {
      display: none;
    }

    &__item {
      position: relative;
      padding: 0;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &::before {
        content: '';
        display: block;
        width: 2px;
        height: 48px;
        background: $lineGreen;
        position: absolute;
        top: 40%;
        left: 0;
        margin-left: 0;
      }

      &-text {
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      box-sizing: border-box;
      color: $black;
      border-bottom: 6px solid transparent;
      margin-left: 2px;

      &:hover {
        .nav__item-text {
          transition: all .3s ease;
          color: $white;
        }
      }

      &-active {
        border-bottom-color: $blue;

        .nav__img {
          display: block;
        }
      }
    }

    &__img {
      width: 100%;
      max-height: 100%;
      display: none;
      transition: all .3s ease;

      &-wrapper {
        width: 53px;
        height: 36px;
      }
    }
  }

  .menu-auth {
    &__item {
      &:first-of-type {
        &::before {
          display: none;
        }
      }
    }
  }
}