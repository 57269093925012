@import "../theme/index";

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .gritx-btn {

    &:hover:not(:disabled) {
      transition: color 0.3s;
      color: $white;
    }

    &:disabled {
      cursor: default;
      background-color: $grey;
    }

    &.gritx-btn-primary {
      background-color: $blue;

      &:active {
        background: darken($blue, 6%);
      }

      &:disabled {
        background-color: $grey;
        color: $alto;
      }
    }
  }

  .chat__message-right .chat__message-value {
    float: right;
    text-align: right;
    background: $lineGreen;
    margin-bottom: 6px;
    font-weight: 400;

    &:before {
      border-left-color: $lineGreen;
    }
  }

  .chat__container {
    display: flex;
    @include respond-to(largedesktop) {
      display: block;
    }
  }

  .bot-chat__wrapper {
    padding: 0 0 100px 41px;
    @include respond-to(largedesktop) {
      padding: 0 41px 100px;
    }
  }

}

.container {
  max-width: 1920px;
  margin: 0 auto;

  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main {
  margin-top: 100px;
  flex: 1 1 auto;
  @include respond-to(tablets) {
    padding-top: 25px;
  }
  @include respond-to(desktop) {
    padding-top: 50px;
  }
  @include respond-to(mobile) {
    margin-top: 70px;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}